import './About.css'
import React from 'react';
import Timeline from '../../components/Timeline';
import Header from '../../components/layout/Header';
import Footer from '../Footer';
import ScrollButton from '../../components/scrollb/ScrollButton';

const About = () => {


  const stats = [
    { percentage: "91%", description: "Returning client base" },
    { percentage: "100%", description: "Projects Launched" },
    { percentage: "80%", description: "Happy clients" },
    { percentage: "18%", description: "Countries Served" },
  ];

  return (
    <div>
      <Header />
      <ScrollButton />
      <div className='container mx-auto'>
        <h1 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734] py-10">About BitFront Infotech
          <p>We are the <span className='text-[#0652dd]'>Business Transformers</span></p>
        </h1>
        <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal md:px-28 px-5">Innovative tech is built by a team of creators and future-centric people. Everyone at BitFront
          is here for the same purpose - to help you become a digital leader.</p>
      </div>
      <div className="container mx-auto mt-[70px]">
        <div className='grid md:grid-cols-2 grid-cols-1 gap-[3rem]'>
          <img alt='img' className="rounded-[30px]" src="/images2/49p.jpg" />
          <div>
            <img alt='img' className="rounded-[30px]" src="/images2/50p.jpg" />
            <div className='px-[30px] pt-8 grid grid-cols-2 gap-[2rem]'>
              {stats.map((stat, index) => (
                <div className="" key={index}>
                  <div className="relative">
                    <div className="bg-gradient-to-b from-blue-800 to-transparent opacity-10 rounded-full w-24 h-24"></div>
                    <p className="absolute text-[54px] leading-none tracking-[-0.01em] text-[#0652dd] bottom-0 font-bold">{stat.percentage}</p>
                  </div>
                  <p className="mt-8 text-[#4C5A67] text-[17px]">{stat.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
        <div className="flex items-center gap-[0.5rem]">
          <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
          <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
        </div>
      </div>
      <div className="mt-[5px] text-[55px] opacity-[0.05] text-center -ml-[21px]">
        <h2 className="mt-[46px] w-full top-[15px] text-[55px] ">BitFront InFotech</h2></div>
      <div className="max-w-max leading-none text-center xl:pl-[730px] lg:pl-[280px] sm:pl-[155px] mt-[-180px] sm:mt-[-140px]">
        <h2 className="mt-[46px] w-full top-[15px] text-[55px]">BitFront InFotech</h2></div>
      <div className="lg:flex md:flex-wrap justify-center items-center gap-4 mt-20">
        <div className='sm:ml-4 lg:ml-0'>
          <p className="text-[28px] font-semibold">A Brief History</p>
          <div className="border-5 border-[#0652dd] w-[106px] mt-5 font-noir-pro"></div>
          <p className="text-lg leading-[40px]">
            <b>Born from a Need for Expertise:</b> BitFront’s story began from a<br />
            burgeoning digital landscape full of options but lacking true<br />
            specialists. Founders Kundan Mishra envisioned a different path,<br />
            where innovation and technology would bridge the gap for <br />
            businesses, both established and nascent. Their mission with BitFront:<br />
            is to tackle the digital challenges of modern enterprises and startups<br />
            with expertise and agility.<br />
            Rapid Growth & Impactful Projects: BitFront’s journey has been a<br />
            testament to its founding vision. Since 2020, we’ve scaled swiftly,<br />
            turning ideas into significant digital products with achievements such<br />
            as funding, millions of downloads, and global recognition. Our<br />
            milestones speak volumes:
          </p>
        </div>
        <img alt='img' className="sm:w-[600px] sm:h-[400px] w-[300px] h-[200px] lg:m-0 sm:m-auto" src="/images2/52p.jpg" />
      </div>
      <Timeline />
      <p
        className=" xl:w-[800px] text-center md:m-auto md:mt-[70px] md:w-[700px] xl:text-[19px] lg:text-[18px] md:text-[17px] text-[16px] mt-10  xl:mt-[70px]">
        This is just the beginning. BitFront's narrative revolves around ongoing growth, driven by
        a dedication to creating exceptional digital experiences.  We<br />
        stand as your reliable partner, prepared to turn your vision into
        reality through innovation, expertise, and a joint commitment to success.
      </p>
      <h1
        className="xl:text-[36px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-24 border-gray-300 pb-12 xl:w-[1350px] xl:ml-[300px] border-b-[2px] ">
        We <span className="text-[#0652dd]">empower forward-thinking</span>
        <br />
        businesses to succeed</h1>
      <div className="sm:flex flex-wrap gap-4 xl:gap-[18rem] mt-8 md:mt-[150px] justify-center items-center">
        <div className="marker-flex">
          <div className="rounded-div1"></div>
          <div>
            <h1 className="rounded-21">Winning Trust and Awards</h1>
            <button className="AWARDS-btn"><b>AWARDS & RECOGNIZED</b></button>
          </div>
        </div>
        <div>
          <p className=" leading-[30px] sm:mt-0 mt-5 xl:text-[20px] lg:text-[18px] md:text-[18px] text-[16px] ">
            We excel at creating exceptional products that capture the attention<br />
            and usage of millions worldwide. The dedication we invest in each<br />
            project is recognized not only by our clients but also by their<br />
            customers and influential figures in the market.
          </p>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About;
