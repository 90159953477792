import React from 'react';
function ML() {
  return (
    <div>
      <div className="p-1">
        <h1 className="text-[30px] font-semibold text-[#ffffff] leading-10 mb-5">Machine Learning</h1>
        <p className="text-base leading-[30px] text-[#ffffff] font-normal mb-[20px]">As an innovative AI development
         company, our experts at Bitfront use machine learning to create AI solutions. These solutions efficiently process
          unstructured data, turning it into actionable insights to drive business growth.</p>
        <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
          <div className=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Read More </div>
        </button>
      </div>
    </div>

  );
}

export default ML;
