import './Phone.css';
import { FaStar } from "react-icons/fa6";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FAQ from '../../components/FAQ/FAQ';
import Footer from "../Footer";
import Header from '../../components/layout/Header';
import IOSApp from '../../assets/images/IOSApp.png';
import CustomiOSApp from '../../assets/images/CustomiOSApp.png';
import iOSAppConsultation from '../../assets/images/iOSAppConsultation.svg';
import iOSDesign from '../../assets/images/iOSDesign.svg';
import CustomAppDevelopment from '../../assets/images/CustomAppDevelopment.svg'
import iOSSoftwareTesting from '../../assets/images/iOSSoftwareTesting.svg';
import iOSappporting from '../../assets/images/iOSappporting.svg';
import Multiplatformdeployment from '../../assets/images/Multiplatformdeployment.svg';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const IPhoneApplication = () => {

  const navigate = useNavigate();

  const handleClickContactus = () => {
    navigate('/Contact-us');
  };

  const [faqs, setFaqs] = useState([
    {
      question: "How will you keep my iOS app idea confidential?",
      answer:
        "We will ensure complete confidentiality of your iOS application by signing an NDA with you. Even before we get on the first call, we will send you an NDA stating that we will not share your application concept with anyone.",
      open: false
    },
    {
      question: "How do you allocate resources for my iOS application development project?",
      answer: "We make this decision on the basis of your app’s features and technical complexity, the need for specialists, and the size of the application. The more complex or large-scaled your iOS project is, the more resources we will allocate to it and the same will decide the experience level of the resources.",
      open: false
    },
    {
      question:
        "How do you meet the current technological trends in native iOS app development?",
      answer: "Our team of iOS subject matter experts follow a practice of thorough market research and extensive research and development. We run hypothesis testing on every new technology and feature set ensuring that we are ready to build every digital use case",
      open: false
    },
    {
      question:
        "Why should I choose BitFront as an iOS app development service provider?",
      answer: "There can be a number of reasons for why you choose BitFront as your iOS technology partner. However, the one primary reason why businesses - across startups and established firms choose us is because of our extensive experience set. ",
      open: false
    },
    {
      question:
        "What are your engagement models?",
      answer: "There are primarily 3 engagement models that we use.First, a fixed work model - here the exact expectations and costs are decided from day one.Second, time and resource model - here, we sit with you to understand the project and then divide it into milestones, following which we define the development timeline and resources.Third is where you can hire individual resources such as iOS app developers to work alongside your in-house development team.",
      open: false
    },
    {
      question:
        "What is the cost to develop an iOS, iPhone and iPad application?",
      answer: "It will be difficult to give one answer to the question without looking into your app requirements. The cost of an iOS app is dependent on a number of elements - features, design, number of platforms it will get live on, complexity, technology additions, and the geographical location of the iOS app development company.",
      open: false
    },
    {
      question:
        "How much time does it take to develop iOS application?",
      answer: "Like the development cost, the time it takes to build an iOS application also depends on multiple factors like - feature sets, technology inclusion, number of platforms, etc. All these factors come together to define the development timeline.",
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const categories = [
    { alt: "Healthcare", src: hhelth, title: "Healthcare" },
    { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
    { alt: "Social networking", src: networking, title: "Social networking" },
    { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
    { alt: "Travel", src: Travel, title: "Travel" },
    { alt: "Media", src: Media, title: "Media" },
    { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
    { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
  ];
  const reviews = [
    {
      rating: 5.0,
      reviewText: "Their attention to detail and dedication to providing high-quality solutions were obvious...",
      author: "Founder & CEO, Larrea Wealth Management",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "Their enthusiasm for the tech sector was visible in their work as well as in their ongoing...",
      author: "Co-Founder & CEO, FLOHOM",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "They've become a seamless member of our team, exemplifying the essence of...",
      author: "Founder & CEO, FilmUp",
      verified: "Verified Review"
    },
    {
      rating: 5.0,
      reviewText: "BitFront's tech skills are seriously top-notch, and they've managed the whole project like professionals.",
      author: "Founder, Level7 Legal",
      verified: "Verified Review"
    }
  ];
  return (
    <div>
      <Header />
      <ScrollButton />
      <div className="max-w-7xl h-auto bg-white rounded-2xl m-auto mt-[40px]">
        <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
          <div>
            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[18px] font-semibold">
              <span className="text-[#0652dd]">IOS App </span>
              Development<br /> Company
            </h1>
            <p className="text-[16px]">
              Helping businesses enjoy more app earnings and strong security with<br />
              our well-planned iOS app development services
            </p>
            <li className="leading-[30px]">iOS App Development Consultation</li>
            <li className="leading-[30px]">iOS UI/UX Design</li>
            <li className="leading-[30px]">Custom iOS Application Development</li>
            <li className="leading-[30px]">iOS Software Testing</li>
            <li className=" mb-[20px] leading-[30px]">Multi-platform Deployment</li>
            <button className="bg-[#0652dd] border border-[#0652dd] rounded-full text-white transition-all duration-200 w-[230px] h-[40px]"><b>TALK TO OUR EXPERTS</b></button>
          </div>
          <img alt='IOSApp' className="w-[550px] h-[400px]" src={IOSApp} />
        </div>
      </div>
      <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
        <div className="flex items-center gap-[0.5rem]">
          <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
          <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
        </div>
        <div className="cont-no">
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
          {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
          <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
          <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
          <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
          <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
          {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
          <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
          <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
          <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
          <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
        </div>
      </div>
      <div className="bg-white w-full h-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap md:flex-row justify-center items-center gap-16 pt-10">
          <div>
            <h1 className="text-3xl md:text-4xl font-semibold">
              Custom <span className="text-[#0652dd]">iOS App<br /> Development </span>
              by BitFront
            </h1>
            <p className="text-base md:text-lg leading-7 mt-4">
              At BitFront, we specialize in making custom iOS apps. Our team of skilled<br />
              developers creates secure, strong, feature-rich, and user-friendly<br />
              applications for iPhone, iPad, Apple Watch, and Apple TV. We have experts<br />
              in iOS app design, development, testing, and launching. Our designers and<br />
              developers work closely together to guarantee top-quality results.
            </p>
            <p className="text-base md:text-lg leading-7 mt-4">
              Our complete <b><u>custom iOS app development services</u></b> have supported<br />
              businesses in turning their unique ideas into appealing iOS apps with top-<br />
              notch features. With our expertise, flexible approach, and teamwork, we<br />
              continue to lead as the top iOS development company.
            </p>
            <p className="text-base md:text-lg leading-7 mt-4">
              We are an <b><u>iOS mobile app development company</u></b>with a dedicated team<br />
              of iPhone app developers that provide custom iPhone application<br />
              development services keeping in regard the specific design and iOS<br />
              application development company guidelines related to all individual<br />
              platforms. We have a highly skilled team of experienced iPhone mobile app<br />
              developers and our primary tech stack for developing iOS solutions is<br />
              Objective-C and Swift.
            </p>
          </div>
          <img alt='CustomiOSApp' className="w-[450px] h-[450px]" src={CustomiOSApp} />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-center mt-[25px] gap-8 lg:gap-[3rem] px-4 lg:px-0">
        <div>
          <h1 className="text-[28px] lg:text-[40px]">iOS App Development
            <span className="text-[#0652dd]"> Services<br /> We Offer</span>
          </h1>
          <p className="text-base lg:text-lg leading-7 mt-4">
            At BitFront, our iPhone and iPad app developers know how to make great<br />
            apps that leave a lasting impression and turn new users into paying<br />
            customers. Regardless of your industry, our top-notch iOS app<br />
            development services can help you attract a high return on investment.
          </p>
          <p className="text-base lg:text-lg leading-7 mt-4">
            BitFront offers end-to-end iOS app development services, including design,<br />
            development, testing, support, and maintenance. Our expert team can take <br />
            on any challenge, from designing an elegant user interface and experience,<br />
            providing high-end coding solutions to completing the full cycle of<br />
            developing iOS apps. Choosing iOS application development means opting<br />
            for secure and scalable digital solutions. Our iOS software developers<br />
            specialize in crafting applications that move your business towards the<br />
            future.
          </p>
          <button className="w-[140px] h-[40px] bg-[#0652dd] text-[#fff] rounded-[70px] border-[1px] border-[#0652dd] cursor-pointer hover:bg-[#fff] hover:text-[#0652dd] transition duration-100 mt-6">
            <b>GET ESTIMATE</b>
          </button>
        </div>

        <div className="flex flex-col gap-6">
          <div className="w-full lg:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-3">
              <img alt='iOSAppConsultation' className="w-10 h-10" src={iOSAppConsultation} />
              <h4>iOS App Development Consultation</h4>
            </div>
            <p className="text-[16px] lg:text-[17px] pl-[10px]">
              When we create an iOS app for you, we make sure to start by understanding your idea. Our team talks with you to plan what your app needs and what features will make it successful.
            </p>
          </div>

          <div className="w-full lg:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-3">
              <img alt='iOSDesign' className="w-10 h-10" src={iOSDesign} />
              <h4>iOS UI/UX Design</h4>
            </div>
            <p className="text-[16px] lg:text-[17px] pl-[10px]">
              We follow Apple's design rules to create an architecture that's user-friendly, memorable, and easy to use. Our designers make stories that showcase your vision in a cool way, making sure to catch users' eyes on the App Store.
            </p>
          </div>

          <div className="w-full lg:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
            <div className="flex items-center gap-3">
              <img alt='CustomAppDevelopment' className="w-10 h-10" src={CustomAppDevelopment} />
              <h4>Custom iOS App Development</h4>
            </div>
            <p className="text-[16px] lg:text-[17px] pl-[10px]">
              Our custom iOS app development services are built with top technology and deep market knowledge. We make sure your app is strong, efficient, and can grow as needed, all while focusing on top quality throughout the development process.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row justify-center gap-8 lg:gap-[6rem] mt-[40px] px-4 lg:px-0">
        <div className="w-full lg:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px] mb-6 lg:mb-0">
          <div className="flex items-center gap-3">
            <img alt='iOSSoftwareTesting' className="w-10 h-10" src={iOSSoftwareTesting} />
            <h4>iOS Software Testing</h4>
          </div>
          <p className="text-[16px] lg:text-[17px] pl-[10px]">
            We test your app at every step of its creation to make sure it's top quality and secure. Our QA team uses both manual checks and automated tools to ensure your app is known for its excellence.
          </p>
        </div>

        <div className="w-full lg:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px]">
          <div className="flex items-center gap-3">
            <img alt='iOSAppPorting' className="w-10 h-10" src={iOSappporting} />
            <h4>iOS App Porting</h4>
          </div>
          <p className="text-[16px] lg:text-[17px] pl-[10px]">
            Do you have an Android or Windows app and want to switch to iOS? We're the iPhone app experts who can assist. We've helped over 300 businesses move their apps to iPhone and iPad smoothly, with little downtime.
          </p>
        </div>
      </div>

      <div className="w-full lg:w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[1px] m-auto mt-[40px] px-4 lg:px-0">
        <div className="flex items-center gap-3">
          <img alt='MultiplatformDeployment' className="w-10 h-10" src={Multiplatformdeployment} />
          <h4>Multi-platform Deployment</h4>
        </div>
        <p className="text-[16px] lg:text-[17px] pl-[10px]">
          We're the experts in iPhone app development, and we'll help you navigate the wide Apple ecosystem. Whether it's for iPhones, iPads, Apple TV, or wearables, we'll make sure your app is top-notch and delivered with quality every time.
        </p>
      </div>

      <div className="mt-12 px-4 md:px-[30px] py-8 md:p-[30px_280px] w-full max-w-screen-xl h-auto bg-white mx-auto">
        <div className="w-full h-auto bg-[#fafafa] p-5">
          <p className="text-2xl md:text-3xl px-5 py-5 flex gap-2 justify-center md:justify-start">
            BitFront Reviews 5.0
            <span className="text-[#e62415] flex items-center">
              <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
            </span>
          </p>
          <div className="flex flex-col md:flex-row justify-center items-center gap-7">
            {reviews.map((review, index) => (
              <div key={index} className="w-full md:w-72 h-auto bg-white rounded-lg p-6 hover:shadow-lg">
                <p className="text-lg flex gap-2 items-center">
                  {review.rating}
                  <span className="text-[#e62415] flex items-center">
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                  </span>
                </p>
                <p className="text-base pt-4">
                  "{review.reviewText}"
                  <br /><br />
                  {review.author}
                  <br /><br />
                  {review.verified}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <h1 className="text-[40px] text-center mt-[50px] font-semibold">
        Our <span className="text-[#0652dd]">iOS App Development</span> Process
      </h1>
      <p className="text-center">
        BitFront handles every step of iOS app development, from idea to support. We're an iOS app development company that helps businesses with strategy,<br />
        creating memorable experiences, and coding valuable apps.
      </p>
      <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
          <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
        </div>
        <div className="flex items-center">
          <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
        </div>
      </div>
      <h1 className="text-[40px] text-center mt-[60px] font-semibold">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>
      <p className="text-[18px] text-center">
        BitFront has worked with a range of businesses, no matter their industry or size, transitioning to digital with iOS apps.
        We help pick the right tech, make<br /> user-friendly apps, and smoothly fit them into your systems.
      </p>
      <div className="flex flex-wrap justify-center items-center gap-7">
        {categories.map((category, index) => (
          <div
            key={index}
            className="hover:shadow-2xl transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
          >
            <div className="py-10 flex justify-center">
              <img alt={category.alt} src={category.src} width="60" height="60" />
            </div>
            <h4 className="text-center">{category.title}</h4>
          </div>
        ))}
      </div>

      <div className="flex mt-[40px] mb-[10px] justify-center">
        <div>
          <h1 className="text-[#0652dd] text-[28px] lg:text-[40px] pt-[30px] text-center lg:text-left">
            Why Choose Us?
          </h1>
          <h1 className="text-[18px] lg:text-[23px] text-center lg:text-left">
            Hire the Team That Understands How<br />
            Digital Success Works
          </h1>
          <p>
            BitFront specializes in providing innovative and custom iOS app solutions for<br />
            businesses of any size, focusing solely on iOS app development.
          </p>
          <h5 className="flex items-center text-[16px] mb-[20px]">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Global quality standards.</h5>
          <h5 className="flex items-center text-[16px] mb-[20px]">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Monthly/yearly hiring.</h5>
          <h5 className="flex items-center text-[16px] mb-[20px]">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Flexible office hours depend on the timezone.</h5>
          <h5 className="flex items-center text-[16px] mb-[20px]">
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="fa-check" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
              </path></svg>Daily/weekly/monthly reporting via email.</h5>
        </div>
      </div>
      <div className="bg-blue-700 mb-[40px] w-3/4 h-auto mx-auto text-white text-center p-8 rounded-lg mt-10">
        <h3 className="text-center text-3xl font-bold"> How much does it cost to develop a mobile application?</h3>
        <p className="text-lg">The cost of developing an iOS app can vary from $10,000 to $50,000 & more, depending on factors such as the complexity of the app, features <br />
          required, design complexity, development time, and the location and rates of the development team.</p>
        <div>
          <button onClick={handleClickContactus} className=" text-[#0652dd] text-[20px] font-semibold w-[140px] h-[42px] bg-[#fff] rounded-[25px] border-[1px] border-[#0652dd] cursor-pointer mt-[20px]">Contact Us</button>
        </div>
      </div>
      <h1 className="text-[30px] text-center font-semibold">Building <span className="text-[#0652dd]">Immersive </span>
        Apple  <span className="text-[#0652dd]">Solutions</span></h1>
      <p className="text-[18px] text-center">We are the name behind some of the most loved iOS apps around the world.</p>

      <button
        className="w-[220px] h-[42px] rounded-full bg-[#0652dd] border-[1px] border-[#0652dd] text-[#fff] mt-[40px] xl:ml-[840px] lg:ml-[400px] p-[10px] text-[15px] inline-block transition duration-150 cursor-pointer hover:bg-[#fff] hover:text-[#0652dd] ">
        <b>VIEW ALL PORTFOLIOS</b>
      </button>
      <h1 className="container mx-auto text-[24px] font-medium m-auto flex mt-[20px] justify-center">FAQs</h1>
      <div className="App">

        <div className="faqs">
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IPhoneApplication;