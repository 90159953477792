import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import SocialMediaAppDevelopment from '../../assets/images/SocialMediaAppDevelopment.png';
import customsocialnetworking from '../../assets/images/customsocialnetworking.png';
import instantmessaging from '../../assets/images/instantmessaging.png';
import socialgaming from '../../assets/images/socialgaming.png';
import mediasharing from '../../assets/images/mediasharing.png';
import OurSocialNetworkingAppDevelopment from '../../assets/images/OurSocialNetworkingAppDevelopment.png';
import communityappdevelopment from '../../assets/images/communityappdevelopment.png';
import datingappdevelopment from '../../assets/images/datingappdevelopment.png';
import socialnetworking from '../../assets/images/socialnetworking.png';
import messagingapp from '../../assets/images/messagingapp.png';
import mediasharings from '../../assets/images/mediasharings.png';
import easysignup from '../../assets/images/easysignup.png';
import contentposting from '../../assets/images/contentposting.png';
import arfilters from '../../assets/images/arfilters.png';
import mediaediting from '../../assets/images/mediaediting.png';
import reviewmechanism from '../../assets/images/reviewmechanism.png';
import realtimechat from '../../assets/images/realtimechat.png';
import SocialMediaAppFeaturesddd from '../../assets/images/SocialMediaAppFeaturesddd.png';
import smartsearches from '../../assets/images/smartsearches.png';
import advertismentplacement from '../../assets/images/advertismentplacement.png';
import performanceanalysis from '../../assets/images/performanceanalysis.png';
import streaminglive from '../../assets/images/streaminglive.png';
import socialecommerce from '../../assets/images/socialecommerce.png';
import paymentintegrationdf from '../../assets/images/paymentintegrationdf.png';
import transparencyprocess from '../../assets/images/transparencyprocess.png';
import agiledevelopmentapproachas from '../../assets/images/agiledevelopmentapproachas.png';
import competitivepricing from '../../assets/images/competitivepricing.png';
import highcustomersatisfactiondfg from '../../assets/images/highcustomersatisfactiondfg.png';
import integritytransparency from '../../assets/images/integritytransparency.png';
import flexibleengagement from '../../assets/images/flexibleengagement.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const SocialNetworking = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is the cost of building a social media app?",
            answer:
                "Logistics software development is the solution to automate shipping and freight forwarding activities and allow transport companies to simplify freight operations, save time, reduce costs and improve customer services. A widely used solution for this is a transportation management system (TMS).",
            open: false
        },
        {
            question: "Can you build a social media app for both iOS and Android?",
            answer: "The four types of logistics are inbound logistics, outbound logistics, reverse logistics, and third-party logistics (3PL) or fourth-party logistics (4PL).",
            open: false
        },
        {
            question:
                "How do you ensure the confidentiality of my app idea?",
            answer: "It provides more transparency to the supply chain:1. Offering a unified platform for fleet management",
            open: false
        },
        {
            question:
                "What platforms does BitFront support for social media app development?",
            answer: "Yes, we do provide assistance to upgrade your existing system with our latest solutions.",
            open: false
        },
        {
            question:
                "Does BitFront offer customization of social media apps?",
            answer: "Yes we kept it confidential and provide assurance for the same.",
            open: false
        },
        {
            question:
                "How long does it take to create a mobile app for social media?",
            answer: "The final cost of developing a logistics software depends on various factors. However, usually, the average cost of logistics software and mobile app development ranges between $20,000 to $500,000.",
            open: false
        },
        {
            question:
                "Why choose BitFront for your social media app development project?",
            answer: "The time required to develop a logistics software system varies based on factors like technology stack, front and back end development processes, application type, platform, team size, and more",
            open: false
        }
    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Social Media</span> App Development</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            Our team of social media app experts excels in building solutions that connect people
                            with the world to share ideas, connect, and build a community.
                        </p>
                        <li className="mt-2">Social Media Analysis</li>
                        <li className="mt-2">Audio-based Networking</li>
                        <li className="mt-2">Instant Messaging</li>
                        <li className="mt-2">Social Gaming App Development</li>
                        <li className="mt-2">Video-Based Apps</li>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='Social Media App Development' className="w-full md:w-[446px] h-auto md:h-[414px] mt-8 md:mt-0 mx-auto" src={SocialMediaAppDevelopment} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>

            <div className="w-full max-w-[1100px] m-auto p-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-[5rem] items-center">
                    <div>
                        <h1 className="text-[28px] lg:text-[40px] font-semibold">
                            Our <span className="text-[#0652dd]">Social Media</span> App Development Services
                        </h1>
                        <p className="mt-4 text-[16px] lg:text-[18px] text-[#192734]">
                            From custom social networking website design to an extensive development process, our social app developers
                            are skilled in connecting the world with a suite of services.
                        </p>
                        <p className="mt-4 text-[16px] lg:text-[18px] text-[#192734]">
                            BitFront stands out as a leading firm in social media app development. We've created highly successful and
                            innovative social media apps for various needs. Our focus on users and advanced technology has earned us
                            recognition as a trusted agency for social media app development in India. Our approach benefits both users
                            and business owners alike, delivering impactful results. Let us assist you in the future of social media
                            interaction and increase your digital presence.
                        </p>
                    </div>
                    <div className="flex flex-col items-center space-y-8">
                        <div className="w-full max-w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#988c33] border-[2px]">
                            <div className="flex items-center gap-2 mb-2">
                                <img alt="custom social networking" className="w-8 h-8" src={customsocialnetworking} />
                                <h4 className="text-[18px] lg:text-[20px] font-bold">Custom Social Networking</h4>
                            </div>
                            <p className="text-[15px] lg:text-[17px] pl-2">
                                We specialize in creating social networking apps that enable secure, real-time connections among users. Additionally, our team of social media app developers
                                integrates a robust mechanism for non-intrusive revenue generation, ensuring profitability from day one.
                            </p>
                        </div>
                        <div className="w-full max-w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-[20px] border-[#339882] border-[2px]">
                            <div className="flex items-center gap-2 mb-2">
                                <img alt="instant messaging" className="w-8 h-8" src={instantmessaging} />
                                <h4 className="text-[18px] lg:text-[20px] font-bold">Instant Messaging</h4>
                            </div>
                            <p className="text-[15px] lg:text-[17px] pl-2">
                                The digital-age messaging architecture has made person-to-person communication engaging and real-time.
                                At BitFront, we design messaging apps on strict encryption modules packed with features like media and
                                document sharing, video and voice calls, group messaging, and more.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-wrap justify-center gap-4 p-4">
                <div className="w-full max-w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-6 mt-4 border-[#984133] border-[2px]">
                    <div className="flex items-center gap-2 mb-2">
                        <img alt="social gaming" className="w-8 h-8" src={socialgaming} />
                        <h4 className="text-[18px] md:text-[20px] font-bold">Social Gaming</h4>
                    </div>
                    <p className="text-[15px] md:text-[17px] pl-2">
                        We excel at creating microgames that can be played on social media platforms. Our team of social media app developers
                        makes it easy to play games with others on the platform, share the scores on their profiles, and invite friends.
                        We help clients build lightweight, interactive games that are both enjoyable to play and share with friends.
                    </p>
                </div>
                <div className="w-full max-w-[556px] h-auto bg-white rounded-tl-[30px] rounded-br-[30px] p-6 mt-4 border-[#337898] border-[2px]">
                    <div className="flex items-center gap-2 mb-2">
                        <img alt="media sharing" className="w-8 h-8" src={mediasharing} />
                        <h4 className="text-[18px] md:text-[20px] font-bold">Media Sharing</h4>
                    </div>
                    <p className="text-[15px] md:text-[17px] pl-2">
                        At BitFront, we've developed apps similar to Instagram and Pinterest, simplifying media sharing—both images and
                        videos—among users on the platform. Our applications facilitate users in exploring their creativity and sharing
                        it securely with the world on a hack-proof platform.
                    </p>
                </div>
            </div>


            <h1 className=" xl:w-[490px] m-auto xl:text-[35px] lg:text-[27px] md:text-[20px] text-[16px] font-semibold text-center mt-10">Our <span className="text-[#0652dd]">Social Networking</span> App Development Solutions</h1>
            <p className="xl:text-[18px] xl:w-[1340px] m-auto font-medium text-center">Our social networking app development solutions can expand your business network, enhance brand credibility,
                boost conversion rates and revenue, and improve customer service and satisfaction.</p>


            <div className="flex flex-wrap justify-center items-center gap-10 mt-10 p-4">
                <img
                    alt="Our Social Networking App Development"
                    className="w-full max-w-[624px] h-auto"
                    src={OurSocialNetworkingAppDevelopment}
                />
                <div className="flex flex-col items-start w-full max-w-[550px] gap-6">
                    <div className="w-full h-auto border-[#afe5a6] border-[2px] rounded-[10px] p-4 flex items-center gap-4">
                        <img alt="community app development" src={communityappdevelopment} />
                        <h1 className="text-[18px] md:text-[20px] font-medium">Community App Development</h1>
                    </div>

                    <div className="w-full h-auto border-[#a6e5e3] border-[2px] rounded-[10px] p-4 flex items-center gap-4">
                        <img alt="dating app development" src={datingappdevelopment} />
                        <h1 className="text-[18px] md:text-[20px] font-medium">Dating & Matrimony App Development</h1>
                    </div>

                    <div className="w-full h-auto border-[#a6b7e5] border-[2px] rounded-[10px] p-4 flex items-center gap-4">
                        <img alt="social networking" src={socialnetworking} />
                        <h1 className="text-[18px] md:text-[20px] font-medium">Social Networking App Development</h1>
                    </div>

                    <div className="w-full h-auto border-[#c0a6e5] border-[2px] rounded-[10px] p-4 flex items-center gap-4">
                        <img alt="messaging app" src={messagingapp} />
                        <h1 className="text-[18px] md:text-[20px] font-medium">Messaging App Development</h1>
                    </div>

                    <div className="w-full h-auto border-[#e5a6aa] border-[2px] rounded-[10px] p-4 flex items-center gap-4">
                        <img alt="media sharings" src={mediasharings} />
                        <h1 className="text-[18px] md:text-[20px] font-medium">Media Sharing App Development</h1>
                    </div>
                </div>
            </div>


            <h1 className=" mt-8 xl:text-[38px] lg:text-[29px] md:text-[20px] text-[16px] font-semibold text-center"><span className="text-[#0652dd]">Social Media </span>
                App Features</h1>
            <p className="text-[18px] font-medium text-center m-auto xl:w-[930px]">What makes us a reliable social media app development
                agency is that we carry extensive knowledge of what differentiates an average social media app from a viral one.
                We then pack that knowledge into your app as inbuilt features.
            </p>

            <div className="flex flex-wrap justify-center gap-10 p-4 mt-10">
                <div className="flex flex-col gap-6 w-full max-w-[400px]">
                    <div className="flex items-center gap-4">
                        <img alt="easy signup" src={easysignup} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Easy sign-up</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="content posting" src={contentposting} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Multi-format content posting</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="arfilters" src={arfilters} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">AR-filters</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="media editing" src={mediaediting} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">In-app media editing</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="review mechanism" src={reviewmechanism} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Stringent content review mechanism</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="real time chat" src={realtimechat} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Real-time chat with friends</h1>
                    </div>
                </div>

                <img
                    alt="Social Media App Featuresddd"
                    className="w-full max-w-[416px] h-auto mt-6 md:mt-0"
                    src={SocialMediaAppFeaturesddd}
                />

                <div className="flex flex-col gap-6 w-full max-w-[400px]">
                    <div className="flex items-center gap-4">
                        <img alt="smartsearches" src={smartsearches} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Smart search</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="advertisement placement" src={advertismentplacement} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Non-disruptive advertisement placement</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="performance analysis" src={performanceanalysis} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">AI-based performance analysis</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="live streaming" src={streaminglive} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Live streaming</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="social eCommerce" src={socialecommerce} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Social eCommerce</h1>
                    </div>
                    <div className="flex items-center gap-4">
                        <img alt="payment integration" src={paymentintegrationdf} />
                        <h1 className="text-[16px] md:text-[18px] font-medium">Payment integration</h1>
                    </div>
                </div>
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">
                <span className="text-[#0652dd]">Social Media</span> App Development Process</h1>
            <p className="xl:text-[18px] text-center m-auto mt-4 xl:w-[1100px]">
                Every element of your social media application development services process is backed by market research and customers’ needs.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center xl:text-[38px] lg:text-[29px] md:text-[20px] text-[16px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center items-center gap-10 p-4 mt-8">
                <div className="w-full max-w-[600px] text-center md:text-left">
                    <h1 className="text-2xl md:text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-base md:text-lg mt-4">
                        Our combination of multi-skill expertise and in-depth knowledge of the social media sector makes us one of the go-to social media app development companies.
                    </p>
                </div>

                <div className="flex flex-col md:flex-row gap-10 mt-6">
                    <div className="flex flex-col gap-6 w-full max-w-[300px]">
                        <div className="flex items-center gap-4">
                            <img alt="transparency process" src={transparencyprocess} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">100% Customer Satisfaction</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="agile development approach" src={agiledevelopmentapproachas} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Agile Process</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="competitive pricing" src={competitivepricing} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Competitive Pricing</h1>
                        </div>
                    </div>

                    <div className="flex flex-col gap-6 w-full max-w-[300px]">
                        <div className="flex items-center gap-4">
                            <img alt="high customer satisfaction" src={highcustomersatisfactiondfg} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Dedicated Teams</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="integrity & transparency" src={integritytransparency} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Integrity & Transparency</h1>
                        </div>
                        <div className="flex items-center gap-4">
                            <img alt="flexible engagement models" src={flexibleengagement} />
                            <h1 className="text-[16px] md:text-[18px] font-medium">Flexible Engagement Models</h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">Want to expand your business with our custom Social Media App Development Services?</h1>
            </div>


            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-center text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SocialNetworking
