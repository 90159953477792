import React from "react";
import "./FAQsw.css";

const FAQ = ({ faqq, index, toggleFAQ }) => {
    return (
        <div
            className={"faqq " + (faqq.open ? "open" : "")}
            key={index}
            onClick={() => toggleFAQ(index)}
        >
            <div className=" text-[#fff] faqq-question font-medium text-[20px]">{faqq.question}</div>
            <div className=" text-[#fff] faqq-answer text-base leading-[30px] font-normal">{faqq.answer}</div>
        </div>
    );
};

export default FAQ;
