import React from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import { FaStar } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import CustomEnterpriseSoftware from '../../assets/images/CustomEnterpriseSoftware.png';
import buildingenterprisesoftware from '../../assets/images/buildingenterprisesoftware.png';
import enterprise from '../../assets/images/enterprise.png';
import hhelth from '../../assets/images/hhelth.png';
import EcommerceSolutions from '../../assets/images/EcommerceSolutions.png';
import networking from '../../assets/images/networking.png';
import banking from '../../assets/images/banking.png';
import Travel from '../../assets/images/Travel.png';
import Media from '../../assets/images/Media.png';
import EntertainmentWebSolutions from '../../assets/images/EntertainmentWebSolutions.png';
import EdutechSolutions from '../../assets/images/EdutechSolutions.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const EnterpriseSoftwareDevelopment = () => {

    const categories = [
        { alt: "Healthcare", src: hhelth, title: "Healthcare" },
        { alt: "E-commerce Solutions", src: EcommerceSolutions, title: "eCommerce" },
        { alt: "Social networking", src: networking, title: "Social networking" },
        { alt: "Banking & Finance", src: banking, title: "Banking & Finance" },
        { alt: "Travel", src: Travel, title: "Travel" },
        { alt: "Media", src: Media, title: "Media" },
        { alt: "Entertainment Web Solutions", src: EntertainmentWebSolutions, title: "Games" },
        { alt: "Edutech Solutions", src: EdutechSolutions, title: "Education" }
    ];
    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="container mx-auto my-5 relative flex justify-center">
                <img
                    alt="Custom Enterprise Software"
                    className="rounded-[30px] bg-[#000] w-full xl:h-auto h-[500px] xl:w-[1100px]"
                    src={CustomEnterpriseSoftware}
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center px-4">
                    <h2 className="text-white text-[30px] md:text-[26px] sm:text-[22px] font-semibold">
                        Custom Enterprise Software Development
                    </h2>
                    <p className="font-medium text-white mt-3 mx-auto md:w-[500px] px-4">
                        A trusted company providing software development services for meaningful and complete
                        enterprise app development, helping your business to grow.
                    </p>
                    <div className="flex justify-center xl:mt-10">
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 p-2 w-[250px] h-[42px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>
            <div className="container mx-auto mt-10 max-w-screen-xl px-4">
                <div className="grid lg:grid-cols-2 gap-8 grid-cols-1">
                    <div>
                        <h1 className="text-2xl md:text-xl font-semibold leading-tight">
                            Leading <span className="text-[#0652dd]">Enterprise Software Development</span> Company in India
                        </h1>
                        <img
                            alt="building enterprise software"
                            className="w-full max-w-[636px] h-auto mt-4"
                            src={buildingenterprisesoftware}
                        />
                    </div>
                    <div>
                        <p className="mb-10 text-lg md:text-base font-medium">
                            BitFront provides custom software development services for enterprises looking for unique enterprise applications. With a
                            strong technical background, our experts specialize in building software of any complexity from scratch and seamlessly
                            integrating it with existing environments and systems. You can trust BitFront to shape your project idea, ensure efficient
                            project delivery, and provide comprehensive assistance post-implementation. Partnering with us means receiving long-term
                            support and on-demand customization from our experienced dedicated team.
                        </p>
                        <p className="text-lg md:text-base font-medium">
                            As an enterprise software development company, we offer application design, API integration, implementation, deployment &
                            modernization. At the same time, we have a deep understanding, extensive knowledge, and necessary skills to build a
                            customized enterprise app on top of ready-made business management platforms. We also assist clients in upgrading their
                            platform-based solutions and align them with all the changing requirements as their businesses grow. Our team of talented
                            developers is dedicated to helping businesses and organizations navigate the ever-changing tech landscape.
                        </p>
                    </div>
                </div>
            </div>


            <div className="container mx-auto px-4 mt-10">
                <h1 className="text-2xl md:text-xl text-center font-semibold leading-snug">
                    Our <span className="text-[#0652dd]">Enterprise Software Development</span> Services
                </h1>
                <p className="max-w-screen-lg m-auto text-center text-lg md:text-base font-medium mt-4">
                    Enterprise application development services help businesses to build unique enterprise-level applications to solve complex
                    business challenges. BitFront has a full-cycle development offering that covers the development of new applications,
                    modernization of existing apps, their management, and maintenance.
                </p>
            </div>

            <img alt='enterprise' className="md:w-[500px] w-full m-auto mt-9 h-auto md:h-[500px]" src={enterprise} />
            <h1 className="text-[20px] mb-[20px] xl:w-[1100px] m-auto text-justify font-semibold">
                Our complete product development services include Proof of Concept (PoC), user interface (UI) and user experience (UX) design,
                iterative development, extensive testing and quality assurance (QA), seamless integrations with third-party systems, and ongoing
                support for a secure operational setup.
            </h1>
            <h1 className="text-[32px] font-semibold text-center mt-15 mb-[20px] ">Our <span className="text-[#0652dd]"> Multi-industry</span> Experience</h1>

            <p className="text-lg text-center xl:w-[1300px] m-auto ">
                At BitFront, we specialize in providing software architecture consulting services in India to a diverse range of industries.
                With a deep understanding of each industry’s unique needs, we help our clients achieve their business goals through innovative
                and reliable software architecture consulting solutions.
            </p>
            <div className="flex flex-wrap justify-center items-center gap-7">
                {categories.map((category, index) => (
                    <div
                        key={index}
                        className="hover:shadow-custom transition-all duration-200 w-full sm:w-[320px] h-[250px] bg-white rounded-[20px] mt-[20px] border-[1px]"
                    >
                        <div className="py-10 flex justify-center">
                            <img alt={category.alt} src={category.src} width="60" height="60" />
                        </div>
                        <h4 className="text-center">{category.title}</h4>
                    </div>
                ))}
            </div>


            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Startup App Development</span> Process</h1>
            <p className=" text-center m-auto mt-4 xl:w-[1240px]">
                We customize our startup app development approach for each client, starting by getting to know them.
                Let's explore the potential of your app for your brand and go from there.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[207px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[25px] font-semibold text-[#fff]">What is the cost of startup software development?</h1>
                <p className="text-[18px] text-[#fff]">
                    The cost of developing a startup application can range from $25,000- $150,000 or more, depending on the requirements
                    of the app, such as the choice of platform, technology stack, the number and complexity of features, location of the
                    hired startup web app development company and so on.
                </p>
            </div>

            <div className=" h-auto border-[#c4d9ff] border-t-[2px] border-b-[2px] m-auto mt-[40px]">
                <h1 className="text-center text-4xl mt-12 font-bold">
                    <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
                <div className="flex flex-wrap justify-center gap-9 mt-11 ">
                    <div>
                        <h1 className="text-3xl font-bold">
                            <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                            <span className="text-[#0652dd]">Digital Success Works</span>
                        </h1>
                        <p className=" mb-[40px] text-lg xl:w-[700px]">
                            At BitFront, we provide modern software solutions tailored to your business and customer requirements.
                            Our expertise ensures smooth operation and flexibility in transforming existing software. We guarantee
                            seamless system performance through all changes.
                        </p>
                    </div>
                    <div>
                        <h5 className=" mb-[20px] text-lg flex items-center gap-2">
                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 pt-3 text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                                </path></svg>Agile Methodology</h5>

                        <h5 className=" mb-[20px] text-lg flex items-center gap-2">
                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 pt-3 text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                                </path></svg>Dedicated Project Manager</h5>

                        <h5 className=" mb-[20px] text-lg flex items-center gap-2">
                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 pt-3 text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                                </path></svg>Personalized Consulting</h5>

                        <h5 className=" mb-[20px] text-lg flex items-center gap-2  ">
                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 pt-3 text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                                </path></svg>Accurate Results</h5>

                        <h5 className=" mb-[20px] text-lg flex items-center gap-2  ">
                            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 pt-3 text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                                </path></svg>Comprehensive Solutions</h5>
                    </div>
                </div>
            </div>
            <div className="container mx-auto px-6 py-[30px]">
                <h2 className="lg:text-[40px] text-[30px] font-bold lg:leading-[59px] leading-[44px] text-center text-[#192734]">Helping Create <span className="text-[#0652dd]">Tomorrow’s Brands</span></h2>
                <p className="text-center text-base leading-[30px] text-[#4c5a67] font-normal">We are the mobile app development company that shapes success stories.</p>
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                    <div className="w-[344px] hidden md:block absolute top-[83px] right-[134px]">
                        <p className="text-lg">
                            Re-building Banksathi - A finance app making it easy for financial experts to make money.
                        </p>
                        <div className="inline-row">
                            <div>
                                <span className="text-[#DE5B48] font-medium leading-[46px] flex items-center gap-2 text-[36px]">
                                    4.4
                                    <p className="text-[#DE5B48] text-[10px] flex gap-1">
                                        <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                                    </p>
                                </span>
                                <h1 className="text-xs text-[#DE5B48]">Rating On Play Store</h1>
                            </div>
                            <div>
                                <span className="text-[#DE5B48] font-medium text-[36px] leading-[46px]">$5.4+</span>
                                <h1 className="text-xs text-[#DE5B48]">Million Funding</h1>
                            </div>
                        </div>
                        <h1 className="my-3">1 Million+ downloads</h1>
                        <div className="flex gap-4">
                            <button className="w-[204px] h-[47px] rounded-[70px] border-2 border-[#063d27] cursor-pointer text-[16px] text-[#000000] flex items-center pl-[20px] gap-2 bg-[#E17F71] transition-all duration-100">
                                <FaArrowCircleRight className="rounded-[50%] text-[30px] text-white" />
                                <p><b>View Case Study</b></p>
                            </button>
                            <img className="w-[124px] h-[36px] cursor-pointer" alt="img" src="/images2/41p.jpg" />
                        </div>
                    </div>
                    <div>
                        <img className="w-[146px] h-[37px] cursor-pointer mt-8" alt="img" src="https://alpha.ehr.software/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.905b902e.png&w=828&q=75" />
                        <p className="my-2 text-base leading-[30px] text-[#4c5a67] font-normal">"On paper, Emerge had everything it took to make a profitable application. However, on the execution front, there were multiple loopholes - glitches
                            in apps, modules not working, slow payment disbursement process, etc. Now to make the application as useful as it was on paper in a real world
                            scenario, we had to take every user journey apart and identify the areas of concerns on a technical end."</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img className="rounded-[50px] w-[50px] h-[50px]" alt="img" src='https://miro.medium.com/v2/resize:fit:2400/1*JZNTvEa6NLjf2oEsYucJ6Q.png' />
                        <div>
                            <p>Corey Smith </p>
                            <p>CEO, Emerge</p>
                        </div>
                    </div>
                </div>
                <div className="justify-center flex pt-5">
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default EnterpriseSoftwareDevelopment
