import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import 'animate.css';
import LogisticsSoftwareDevelopment from '../../assets/images/LogisticsSoftwareDevelopment.png';
import LogisticsManagementApps from '../../assets/images/LogisticsManagementApps.png';
import RealtimeTrackingApps from '../../assets/images/RealtimeTrackingApps.png';
import OrderManagementSolutions from '../../assets/images/OrderManagementSolutions.png';
import LogisticsDevelopment from '../../assets/images/LogisticsDevelopment.png';
import CloudandDevOps from '../../assets/images/CloudandDevOps.png';
import AnalyticsandReporting from '../../assets/images/AnalyticsandReporting.png';
import IOT from '../../assets/images/IOT.png';
import GPStechnology from '../../assets/images/GPStechnology.png';
import Blockchain from '../../assets/images/Blockchain.png';
import AIbasedlogisticsmanagement from '../../assets/images/AIbasedlogisticsmanagement.png';
import Automationlogistics from '../../assets/images/Automationlogistics.png';
import Pushnotifications from '../../assets/images/Pushnotifications.png';
import LogisticsSoftwarewithInnovativeFeatures from '../../assets/images/LogisticsSoftwarewithInnovativeFeatures.png';
import Realtimestatustracking from '../../assets/images/Realtimestatustracking.png';
import MultimediaEditing from '../../assets/images/MultimediaEditing.png';
import Integrationplatforms from '../../assets/images/Integrationplatforms.png';
import Bestsoftwaredevelopmentpractices from '../../assets/images/Bestsoftwaredevelopmentpractices.png';
import Clearanddetailedtime from '../../assets/images/Clearanddetailedtime.png';
import Customreports from '../../assets/images/Customreports.png';
import Agile from '../../assets/images/Agile.png';
import transparency from '../../assets/images/transparency.png';
import Regularcommunications from '../../assets/images/Regularcommunications.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Logistic = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "What is logistics software development?",
            answer:
                "Logistics software development is the solution to automate shipping and freight forwarding activities and allow transport companies to simplify freight operations, save time, reduce costs and improve customer services. A widely used solution for this is a transportation management system (TMS).",
            open: false
        },
        {
            question: "What are the 4 major types of logistics?",
            answer: "The four types of logistics are inbound logistics, outbound logistics, reverse logistics, and third-party logistics (3PL) or fourth-party logistics (4PL).",
            open: false
        },
        {
            question:
                "How logistics software development can benefit any logistics organization?",
            answer: "It provides more transparency to the supply chain:1. Offering a unified platform for fleet management",
            open: false
        },
        {
            question:
                "Is it possible to upgrade our existing logistics software?",
            answer: "Yes, we do provide assistance to upgrade your existing system with our latest solutions.",
            open: false
        },
        {
            question:
                "Do you guarantee the security and confidentiality of our data?",
            answer: "Yes we kept it confidential and provide assurance for the same.",
            open: false
        },
        {
            question:
                "How much does it cost to develop custom logistics software?",
            answer: "The final cost of developing a logistics software depends on various factors. However, usually, the average cost of logistics software and mobile app development ranges between $20,000 to $500,000.",
            open: false
        },
        {
            question:
                "How much time does it take to develop a logistics software for transport company?",
            answer: "The time required to develop a logistics software system varies based on factors like technology stack, front and back end development processes, application type, platform, team size, and more",
            open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Logistics Software</span> Development Company</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            At BitFront, we build solutions that power real-time tracking of goods, efficient
                            warehouse management, and make the industry automated.
                        </p>
                        <li className="mt-2">Inventory Management</li>
                        <li className="mt-2">Risk Management</li>
                        <li className="mt-2">Streamlined Operations</li>
                        <li className="mt-2">Real-time Tracking</li>
                        <li className="mt-2">Real-time Information</li>
                        <button className="transition-all text-[15px] duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='LogisticsSoftwareDevelopment' className="w-full md:w-[506px] h-auto md:h-[420px] mt-8 md:mt-0 mx-auto" src={LogisticsSoftwareDevelopment} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>
            <div className="max-w-[1100px] mx-auto px-4">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-8 lg:gap-[5rem] justify-center items-center">
                    <div>
                        <h1 className="text-[28px] lg:text-[40px] font-semibold">
                            <span className="text-[#0652dd]">Logistics Software</span> Development Services
                        </h1>
                        <p className="mt-4 lg:mb-[20px] text-[16px] lg:text-[18px] text-[#192734]">
                            At BitFront, we specialize in creating digital solutions for order tracking and optimizing transportation
                            and distribution processes for manufacturing units. Our commitment to digitizing the logistics sector has
                            established us as a trusted agency for transportation and logistics software development.
                        </p>
                        <p className="mt-4 text-[16px] lg:text-[18px] text-[#192734]">
                            If your business moves quickly and needs help organizing everything, our logistics software can automate
                            many tasks for you. We create software for all your logistics needs, both inside and outside your company,
                            to improve how you deliver goods.
                        </p>
                    </div>
                    <div>
                        <div className="mt-6 lg:mt-[40px] w-full lg:w-[556px] h-auto lg:h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-5 lg:p-[20px] border-[#533398] border-[2px]">
                            <div className="flex items-center gap-2">
                                <img alt='LogisticsManagementApps' className="w-8 h-8 lg:w-9 lg:h-9" src={LogisticsManagementApps} />
                                <h4 className="text-[18px] lg:text-[20px] font-bold">Logistics Management Apps</h4>
                            </div>
                            <p className="text-[15px] lg:text-[17px] pl-2 mt-2">
                                We offer customized logistics software development solutions that run on both mobile and web. The software we
                                make is engineered to offer a detailed view of the logistic movement leading to increased user engagement
                                and real-time data interaction.
                            </p>
                        </div>
                        <div className="mt-6 lg:mt-[40px] w-full lg:w-[556px] h-auto lg:h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-5 lg:p-[20px] border-[#983382] border-[2px]">
                            <div className="flex items-center gap-2">
                                <img alt='LogisticsManagementApps' className="w-8 h-8 lg:w-9 lg:h-9" src={LogisticsManagementApps} />
                                <h4 className="text-[18px] lg:text-[20px] font-bold">Supply Chain Management Solutions</h4>
                            </div>
                            <p className="text-[15px] lg:text-[17px] pl-2 mt-2">
                                At BitFront we build supply chain management software that makes it easy for businesses to track the product’s
                                journey from the manufacturing station to the end users’ doorsteps. The products we build are known to
                                introduce transparency in the industry.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-center items-center mt-8 lg:mt-[40px] gap-6">
                    <div className="w-full lg:w-[556px] h-auto lg:h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-5 lg:p-[20px] border-[#99c2a4] border-[2px]">
                        <div className="flex items-center gap-2">
                            <img alt='RealtimeTrackingApps' className="w-8 h-8 lg:w-9 lg:h-9" src={RealtimeTrackingApps} />
                            <h4 className="text-[18px] lg:text-[20px] font-bold">Real-time Tracking Apps</h4>
                        </div>
                        <p className="text-[15px] lg:text-[17px] pl-2 mt-2">
                            We provide logistics businesses and their customers an IT architecture to track the shipment status in real-time.
                            Our team specializes in creating an API of the tracking mechanism, which can be used commercially as a third-party
                            integration for new eCommerce applications.
                        </p>
                    </div>

                    <div className="w-full lg:w-[556px] h-auto lg:h-[250px] bg-white rounded-tl-[30px] rounded-br-[30px] p-5 lg:p-[20px] border-[#de916d] border-[2px]">
                        <div className="flex items-center gap-2">
                            <img alt='OrderManagementSolutions' className="w-8 h-8 lg:w-9 lg:h-9" src={OrderManagementSolutions} />
                            <h4 className="text-[18px] lg:text-[20px] font-bold">Order Management Solutions</h4>
                        </div>
                        <p className="text-[15px] lg:text-[17px] pl-2 mt-2">
                            Our logistics & transportation mobile app development process comprises creating a solution around order
                            fulfillment and management. We make it easy for businesses to keep track of the number of orders placed along
                            with shipment details, their delivery status, and managing reverse orders.
                        </p>
                    </div>
                </div>
            </div>


            <h1 className="xl:text-[35px] lg:text-[27px] md:text-[19px] text-[16px] font-semibold text-center mt-10"><span className="text-[#0652dd]">Logistics Software</span> Development Solutions</h1>
            <p className="text-[18px] xl:w-[1340px] m-auto font-medium text-center">Our goal is to enhance the overall customer experience
                and facilitate sustainable business expansion through offerings like logistics management systems, transport management
                software, and advanced data analytics solutions. Our team of specialists implements these solutions using cutting-edge
                technologies, including:
            </p>

            <div className="flex flex-wrap justify-center items-center gap-8 lg:gap-[5rem] mt-[40px]">
                <img alt='LogisticsDevelopment' className="xl:w-[561px] xl:h-[557px] w-full h-auto object-cover" src={LogisticsDevelopment} />
                <div className="flex flex-col space-y-10">

                    {[
                        { src: CloudandDevOps, title: "Cloud and DevOps", borderColor: "#afe5a6" },
                        { src: AnalyticsandReporting, title: "Big Data Science and Analytics", borderColor: "#a6e5e3" },
                        { src: IOT, title: "IoT", borderColor: "#a6b7e5" },
                        { src: GPStechnology, title: "GPS technology", borderColor: "#c0a6e5" },
                        { src: Blockchain, title: "Blockchain", borderColor: "#e5a6aa" }
                    ].map(({ src, title, borderColor }) => (
                        <div key={title} className={`mt-[40px] w-full h-auto xl:w-[550px] xl:h-[65px] border-[2px] rounded-[10px] border-[${borderColor}] hover:shadow-lg transition-shadow`}>
                            <div className="flex p-[15px] gap-[2rem] items-center">
                                <img alt={title} className="w-[38px] h-[38px] object-contain" src={src} />
                                <h1 className="text-[20px] font-medium">{title}</h1>
                            </div>
                        </div>
                    ))}

                </div>
            </div>


            <h1 className=" mt-8 xl:text-[38px] lg:text-[28px] md:text-[20px] text-[16px] font-semibold text-center">Powering Your<span className="text-[#0652dd]"> Logistics Software </span>
                with Innovative Features</h1>
            <p className="text-[18px] font-medium text-center m-auto w-full xl:w-[940px]">Incorporating smart, innovative features sets us apart in the logistics and shipment industry.
                Our team specializes in implementing features that position your brand as leaders in the logistics sector.</p>
            <div className="flex flex-col lg:flex-row justify-center items-center xl:gap-[5rem] mt-[60px]">
                <div className="flex flex-col space-y-6">
                    {[
                        { src: AIbasedlogisticsmanagement, title: "AI-based logistics management" },
                        { src: Automationlogistics, title: "Automation of logistics" },
                        { src: Pushnotifications, title: "Push notifications" }
                    ].map(({ src, title }) => (
                        <div key={title} className="flex items-center gap-[2rem]">
                            <img alt={title} className="w-9 h-9" src={src} />
                            <h1 className="text-[18px] font-medium">{title}</h1>
                        </div>
                    ))}
                </div>

                <img alt='LogisticsSoftwarewithInnovativeFeatures' className="w-[556px] h-[379px] object-cover" src={LogisticsSoftwarewithInnovativeFeatures} />

                <div className="flex flex-col space-y-6 xl:w-[400px]">
                    {[
                        { src: Realtimestatustracking, title: "Real-time status tracking" },
                        { src: MultimediaEditing, title: "Multi-payment support" },
                        { src: Integrationplatforms, title: "Integration with CRM, ERP, TMS, and WMS platforms" }
                    ].map(({ src, title }) => (
                        <div key={title} className="flex items-center gap-[2rem]">
                            <img alt={title} className="w-9 h-9" src={src} />
                            <h1 className=" font-medium">{title}</h1>
                        </div>
                    ))}
                </div>
            </div>



            <h1 className="font-bold xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center mt-[20px]">Our
                <span className="text-[#0652dd]"> Logistics Software</span> Development Process</h1>
            <p className="text-[18px] text-center m-auto mt-4 w-full xl:w-[1100px]">
                Every single element of your logistics app is built with a focus on your customers and employees.
                We aim to make the end-to-end order fulfillment process streamlined and efficient.
            </p>
            <div className="flex flex-wrap gap-2 items-center justify-center mt-8">
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">1</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">2</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">3</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">4</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">5</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">6</div>
                    <div className="hidden md:block w-[100px] h-[1px] bg-black mx-2"></div>
                </div>
                <div className="flex items-center">
                    <div className="w-12 h-12 rounded-full bg-blue-600 text-white text-center flex items-center justify-center text-xl">7</div>
                </div>
            </div>

            <h1 className=" mt-10 text-center text-[38px] font-semibold"><span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className=" flex flex-wrap justify-center items-center gap-[5rem] mt-[30px]">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                </div>
                <div>

                    <div className="flex flex-wrap xl:gap-[5rem]">
                        <div className="w-[300px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Bestsoftwaredevelopmentpractices' className="w-9 h-9" src={Bestsoftwaredevelopmentpractices} />
                                <h1 className="text-[18px] font-medium">Best software development practices</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Clearanddetailedtime' className="w-9 h-9" src={Clearanddetailedtime} />
                                <h1 className="text-[18px] font-medium">Clear and detailed time</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Customreports' className="w-9 h-9" src={Customreports} />
                                <h1 className="text-[18px] font-medium">Custom reports</h1>
                            </div>
                        </div>


                        <div className="w-[325px]">
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Agile' className="w-10 h-10" src={Agile} />
                                <h1 className="text-[18px] font-medium">Agile (Scrum, Kanban methodology)</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='transparency' className="w-9 h-9" src={transparency} />
                                <h1 className="text-[18px] font-medium">100% transparency</h1>
                            </div>
                            <div className=" mt-6 gap-[2rem] flex items-center">
                                <img alt='Regularcommunications' className="w-9 h-9" src={Regularcommunications} />
                                <h1 className="text-[18px] font-medium">Regular communications</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=" mt-[40px] m-auto p-[40px] w-full xl:w-[1320px] h-auto xl:h-[147px] border-[1px] border-[#0652dd] rounded-[15px] bg-[#0652dd]">
                <h1 className="text-center text-[20px] font-semibold text-[#fff]">PGet your Business Goals Optimize Efficiency and Reduce Costs with Our Logistics Software Solutions</h1>
            </div>

            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>

            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <h1 className="faqsopara mt-[40px] font-semibold text-center text-[25px]">FAQs</h1>
            <div className="App">

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Logistic
