import React, { useState } from 'react';
import Header from '../../components/layout/Header'
import Footer from "../Footer";
import FAQ from '../../components/FAQ/FAQ';
import HealthcareIT from '../../assets/images/HealthcareIT.png';
import RemotePatientMonitoring from '../../assets/images/RemotePatientMonitoring.png';
import HealthcareSoftware from '../../assets/images/HealthcareSoftware.png';
import HealthcareAppDevelopment from '../../assets/images/HealthcareAppDevelopment.png';
import ExploreWithOurInnovativeHealthcareITSolutions from '../../assets/images/ExploreWithOurInnovativeHealthcareITSolutions.png';
import EncryptedSecurityforAllData from '../../assets/images/EncryptedSecurityforAllData.png';
import UserfriendlyRemoteMedicalCare from '../../assets/images/UserfriendlyRemoteMedicalCare.png';
import Rectangle from '../../assets/images/Rectangle.png';
import ScrollButton from '../../components/scrollb/ScrollButton';
const Healthcare = () => {

    const [faqs, setFaqs] = useState([
        {
            question: "Custom-Based Solutions",
            answer:
                "We attentively consider every requirement, delivering fully personalized solutions for your business at an affordable price. Get in touch with us for a reliable OTT solution.",
            open: false
        },
        {
            question: "Industrial Experts",
            answer: "We have a huge team that understands OTT technology, its functionality, its tools, and how it can enhance your business.",
            open: false
        },
        {
            question: "Seamless Communication",
            answer: "Rest assured of smooth communication flow; we keep our clients informed of every change and gladly welcome feedback.",
            open: false
        },
        {
            question: "Latest Technology",
            answer: "Employing cloud-based infrastructure, we optimize benefits for industrial projects, utilizing the most suitable tools for each tool.",
            open: false
        },

    ]);
    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };
    const steps = [
        { title: "Discover", description: "We evaluate end-user needs, business goals, technology and tools, and project risks to perform a requirement analysis." },
        { title: "Estimate", description: "We estimate project timelines and development costs by considering the business goals, budget, and technology requirements." },
        { title: "Plan", description: "We plan the design and development cycle, utilization of budget and resources, timeline management, and progress tracking systems." },
        { title: "Design", description: "Our designers create user journey maps to personalize the interface, features, and experience for end users." },
        { title: "Develop", description: "We build healthcare software with a scrum-based (Agile) strategy to ensure rapid deliveries and improvements." },
        { title: "Test-Improve-Test", description: "We follow the test-improve-test approach to continuously test the healthcare software, improve it, and test again until it meets quality requirements." },
        { title: "Deploy", description: "We deploy the healthcare software, monitor software performance, and offer suggestions for further improvements." },
        { title: "Support", description: "We offer healthcare support services for dealing with performance/security issues, bugs/errors, preventive maintenance, and releasing new updates." },
    ];

    return (
        <div>
            <Header />
            <ScrollButton />
            <div className="max-w-7xl h-auto bg-white rounded-2xl mx-auto p-5">
                <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-[5rem] justify-center p-[30px]">
                    <div className="text-center md:text-left">
                        <h1 className="text-[28px] md:text-[40px] font-bold">
                            <span className="text-[#0652dd]">Healthcare IT</span><br />Solutions/Consulting</h1>
                        <p className="text-[16px] md:text-[18px] mt-4">
                            BitFront offers extensive healthcare IT services, delivering convenient, secure,
                            and high-performing healthcare software solutions. Our expertise lies in crafting
                            exceptional applications that emphasize user satisfaction and cost-effectiveness.
                            Explore how our solutions can deliver value to both businesses and individual users.
                        </p>
                        <button className="transition-all duration-200 rounded-[20px] bg-[#0652dd] text-white font-bold hover:bg-white hover:text-[#0652dd] border-[#0652dd] border-2 w-full md:w-[280px] mt-10 h-[40px]">
                            TALK TO OUR DEVELOPERS
                        </button>
                    </div>
                    <img alt='HealthcareIT' className="w-full md:w-[550px] h-auto md:h-[513px] mt-8 md:mt-0 mx-auto" src={HealthcareIT} />
                </div>
            </div>

            <div className="xl:w-[1300px] lg:w-[990px] sm:w-[730px] h-[198.8px] mt-[80px] md:ml-4 xl:m-auto overflow-hidden">
                <div className="flex items-center gap-[0.5rem]">
                    <div className="w-[90px] h-[2px] bg-[#000] mt-[10px]"></div>
                    <h1 className="text-[24px] pb-[10px]"><span className="text-[#0652dd]">Trusted by</span> Enterprises and Startups like</h1>
                </div>
                <div className="cont-no">
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                    {/* <img alt='lifecell' className="w-[150px] h-[50px]" src="https://www.lifecell.in/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flifecell_logo-1.5132b15d.png&w=256&q=75" /> */}
                    <img alt='media.licdn' className="w-[200px] h-[50px]" src="images2/aurumlive.png" />
                    <img alt='14p.jpg' className="w-[104px] h-[45px] cursor-pointer" src="/images2/14p.jpg" />
                    <img alt='abroad works' className="w-[180px] h-[50px]" src="images2/abroadworks.png" />
                    <img alt='tire robot' className="w-[122.63px] h-[29.41px] cursor-pointer" src="https://tirerobot.com/_next/static/media/Logo.4299bfd5.svg" />
                    {/* <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" /> */}
                    <img alt='30mins' className="w-[50px] h-[50px]" src="https://30mins.com/assets/logo.svg" />
                    <img alt='9p.jpg' className="w-[122px] h-[20px] cursor-pointer" src="/images2/9p.jpg" />
                    <img alt='familylawassist' className="w-[83px] h-[60px] cursor-pointer" src="https://app.familylawassist.com.au/static/media/mki_logo.8d38de02226b06da1631.png" />
                    <img alt='openhousesdirect' className="w-[175px] h-[30px]" src="https://www.openhousesdirect.com/static/open-to-close-angular/dist/assets/images/logo.png" />
                </div>
            </div>
            <h1 className="text-[#0652dd] text-center text-[32px] md:text-[40px] font-bold">
                Healthcare IT <span className="text-[#000]">Services</span> Offering
            </h1>
            <p className="max-w-full md:w-[47%] mx-auto mt-4 text-[16px] md:text-[18px] text-center">
                Our offerings include electronic health record and medical record software, designed to empower healthcare
                providers in improving their organization's interoperability, efficiency, and patient care.
            </p>

            <div className="gap-[2rem] flex flex-wrap items-center justify-center mt-9">
                <div className="w-full md:w-[404px] h-auto md:h-[400px] border-[2px] rounded-[15px]">
                    <div className="flex gap-[2rem] items-center p-[20px]">
                        <img alt="RemotePatient" className="w-10 h-10" src={RemotePatientMonitoring} />
                        <h1 className="text-[22px] font-bold">Remote Patient Monitoring (RPM) Apps</h1>
                    </div>
                    <ul className="pl-[40px]">
                        <li className="text-[18px] leading-[35px]">User-friendly Virtual Medical Care</li>
                        <li className="text-[18px] leading-[35px]">Improved Staff Productivity</li>
                        <li className="text-[18px] leading-[35px]">Better Patient-doctor Experience</li>
                        <li className="text-[18px] leading-[35px]">Reduced Medical Care Costs</li>
                        <li className="text-[18px] leading-[35px]">24/7 Access to Remote Care</li>
                        <li className="text-[18px] leading-[35px]">Increased Patient Engagement</li>
                    </ul>
                </div>

                <div className="w-full md:w-[444px] h-auto md:h-[400px] border-[2px] rounded-[15px]">
                    <div className="flex gap-[2rem] items-center p-[15px]">
                        <img alt="Healthcare" className="w-10 h-10" src={HealthcareSoftware} />
                        <h1 className="text-[22px] font-bold">Healthcare Software Development</h1>
                    </div>
                    <ul className="pl-[40px]">
                        <li className="text-[18px] leading-[35px]">Remote patient monitoring</li>
                        <li className="text-[18px] leading-[35px]">Online patient registration</li>
                        <li className="text-[18px] leading-[35px]">Online appointment scheduling</li>
                        <li className="text-[18px] leading-[35px]">Appointment reminders and notifications</li>
                        <li className="text-[18px] leading-[35px]">Online bill payments</li>
                        <li className="text-[18px] leading-[35px]">Communities, portals, and secure messaging</li>
                        <li className="text-[18px] leading-[35px]">Patient education</li>
                    </ul>
                </div>

                <div className="w-full md:w-[404px] h-auto md:h-[400px] border-[2px] rounded-[15px]">
                    <div className="flex gap-[2rem] items-center p-[20px]">
                        <img alt="Healthcare" className="w-10 h-10" src={HealthcareAppDevelopment} />
                        <h1 className="text-[22px] font-bold">Healthcare App Development</h1>
                    </div>
                    <ul className="pl-[40px]">
                        <li className="text-[18px] leading-[35px]">Visually attractive interface</li>
                        <li className="text-[18px] leading-[35px]">Easy navigation</li>
                        <li className="text-[18px] leading-[35px]">User-friendly convenience</li>
                        <li className="text-[18px] leading-[35px]">Remote Medical Care</li>
                        <li className="text-[18px] leading-[35px]">Data security encryption</li>
                        <li className="text-[18px] leading-[35px]">Better Patient-doctor Experience</li>
                    </ul>
                </div>
            </div>

            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] font-bold w-[35%] mt-[40px] m-auto text-center">Explore With Our<span className="text-[#0652dd]"> Innovative
                Healthcare IT Solutions
            </span></h1>
            <h1 className=" text-center m-auto w-[45%]">Our healthcare IT solutions simplify remote medical care, increase patient engagement and acquisition, and
                generate better revenue for institutions by incorporating technology like the Internet of Things (IoT), Artificial
                Intelligence(AI), ChatGPT, and Cloud Computing.</h1>

            <div className="w-full max-w-[1200px] h-auto p-3 rounded-[20px] border-[2px] m-auto">
                <img
                    alt="Explore our innovative healthcare IT solutions"
                    className="w-full max-w-[750px] h-auto m-auto p-5"
                    src={ExploreWithOurInnovativeHealthcareITSolutions}
                />
                <h1 className="text-[20px] text-justify p-8 font-bold">
                    BitFront creates an engaging patient experience through custom software development in healthcare.
                    Our team of skilled medical app developers has hands-on experience in building complex healthcare
                    software solutions. Our intuitive medical software development services aim to improve patient care
                    for better medical outcomes.
                </h1>
                <ul className="list-disc pl-[50px]">
                    <li className="text-[18px] leading-[30px] mb-2">Healthcare software development services customizable to your unique business needs.</li>
                    <li className="text-[18px] leading-[30px] mb-2">Crafting a solution that is in sync with your business objectives and customer requirements.</li>
                    <li className="text-[18px] leading-[30px] mb-2">Equal satisfaction and quality to a startup or large enterprise's medical business requirements.</li>
                    <li className="text-[18px] leading-[30px]">Improved medical care and collaboration.</li>
                </ul>
            </div>

            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold p-[20px]">
                <span className="text-[#0652dd]">Healthcare App</span> Development Process We Follow</h1>
            <div className="mt-[40px] w-full max-w-[1272px] h-auto m-auto rounded-[10px]">
                {steps.map((step, index) => (
                    <div key={index} className="mt-[40px] w-full h-auto border-[3px] rounded-[10px]">
                        <div className="flex gap-[20px] sm:gap-[40px] lg:gap-[72px] p-4 sm:pt-[10px] pr-[20px] pb-[10px] pl-[10px]">
                            <h1 className="w-[40px] h-[40px] hover:bg-[#0652dd] hover:text-[#fff] text-center rounded-full text-[18px] border-[2px] flex items-center justify-center">
                                {index + 1}
                            </h1>
                            <div>
                                <h1 className="text-[18px] sm:text-[20px] font-bold">{step.title}</h1>
                                <p className="text-[15px] sm:text-[17px]">{step.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <h1 className="text-[#0652dd] xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-10">Role of IT <span className="text-[#000000]">Technology in Healthcare</span></h1>
            <p className="text-center text-[18px] m-auto w-full md:w-[800px]">IT technology plays many highly contributory roles in improving the outcome of medical care, medical research, and
                chronic disease monitoring and management.</p>
            <div className="flex flex-col sm:flex-row justify-center mt-[20px] gap-[2rem] sm:gap-[5rem]">
                <div className="shadow-custom border-[1px] rounded-[15px] w-full sm:w-[400px] h-auto">
                    <img alt="EncryptedData" className="w-full sm:w-[400px] h-[250px] rounded-tl-[15px] rounded-tr-[15px]" src={EncryptedSecurityforAllData} />
                    <div className="p-[20px]">
                        <h1 className="text-[20px] sm:text-[22px] font-bold">Encrypted Security for All Data</h1>
                        <p className="text-[16px] sm:text-[18px] mt-2">
                            It can provide high security for confidential/sensitive medical documents through HIPAA compliance, data encryption, backup, and restore solutions.
                        </p>
                    </div>
                </div>

                <div className="shadow-custom border-[1px] rounded-[15px] w-full sm:w-[400px] h-auto">
                    <img alt="friendlyCare" className="w-full sm:w-[400px] h-[250px] rounded-tl-[15px] rounded-tr-[15px]" src={UserfriendlyRemoteMedicalCare} />
                    <div className="p-[20px]">
                        <h1 className="text-[20px] sm:text-[22px] font-bold">User-friendly Remote Medical Care through Telemedicine</h1>
                        <p className="text-[16px] sm:text-[18px] mt-2">
                            Build user-friendly remote medical care and monitoring platforms with e-prescriptions, online pharmacies, telemedicine, and EMR/EHR.
                        </p>
                    </div>
                </div>

                <div className="shadow-custom border-[1px] rounded-[15px] w-full sm:w-[400px] h-auto">
                    <img alt="rectangle" className="w-full sm:w-[400px] h-[250px] rounded-tl-[15px] rounded-tr-[15px]" src={Rectangle} />
                    <div className="p-[20px]">
                        <h1 className="text-[20px] sm:text-[22px] font-bold">Easy Accessibility to Records</h1>
                        <p className="text-[16px] sm:text-[18px] mt-2">
                            IT technology can simplify accessibility to resources and data that can automate stable and efficient medical care. For example, telemedicine, EHR, and healthcare cloud computing.
                        </p>
                    </div>
                </div>
            </div>

            <h1 className="text-center text-4xl mt-12 font-bold">
                <span className="text-[#0652dd]">Why</span> Choose BitFront?</h1>
            <div className="flex flex-wrap justify-center gap-9 mt-11 ">
                <div>
                    <h1 className="text-3xl font-bold">
                        <span className="text-[#0652dd]"> Hire the Team</span> That Understands How<br />
                        <span className="text-[#0652dd]">Digital Success Works</span>
                    </h1>
                    <p className="text-lg">
                        With a proven track record in React Native app development,<br />
                        we're the perfect fit for your project. Here's why:
                    </p>
                    <br />
                    <h5 className="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Global quality standards.</h5>
                    <br />
                    <h5 className="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Monthly/yearly hiring.</h5>
                    <br />
                    <h5 className="text-lg flex items-center gap-2">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Flexible office hours depend on the timezone.</h5>
                    <br />
                    <h5 className="text-lg flex items-center gap-2  ">
                        <svg stroke="currentColor" fill="currentColor" viewBox="0 0 1024 1024" className="bg-blue-300 rounded-full w-9 h-8  text-blue-600 p-[5px] text-center" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z">
                            </path></svg>Daily/weekly/monthly reporting via email.</h5>
                </div>
                <div>

                    <div className="App">

                        <div className="faqs">
                            {faqs.map((faq, index) => (
                                <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <h1 className="xl:text-[40px] lg:text-[30px] md:text-[20px] text-[16px] text-center font-bold mt-[70px]">Explore Our Expertise: A Collection of Our Best Creations</h1>
            <p className="text-[18px] text-center">Bring your App Ideas to Life</p>
            <div className="container mx-auto px-6 py-[30px]">
                <div className="bg-white rounded-[30px] mx-auto p-5 h-auto mt-10 cardShadow relative">
                    <img className="h-auto w-full rounded-[40px]" alt="img" src="/images2/emergeSite.png"></img>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Healthcare
