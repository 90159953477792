import React from 'react'

const DL = () => {
    return (
        <div>
            <div className="p-1">
                <h1 className="text-[30px] font-semibold text-[#ffffff] leading-10 mb-5">Deep Learning</h1>
                <p className="text-base leading-[30px] text-[#ffffff] font-normal mb-[20px]">We specialize in delivering
                    advanced deep learning solutions that transform businesses through the power of AI. If you need deep learning
                    services, our team of expert data scientists and ML engineers is ready to assist you.</p>
                <button className="w-[208px] h-[56px] bg-gradient-to-r from-[#08dff8] to-[#b811e5] rounded-[30px] text-[18px]">
                    <div className=" bg-gradient-to-r from-[#26123C] to-[#040206] rounded-[300px] text-[18px] p-[10px] w-[202px] h-[50px] m-auto text-[#fff]">Read More </div>
                </button>
            </div>
        </div>
    )
}

export default DL;
